<template>
    <section class="content">
        <loading :active.sync="isLoading"
        :color="'#39a2db'"
        :loader="'dots'"
        :is-full-page="true"></loading>
        <div class="row">
            <div class="col-lg-9 col-md-9 col-12">
                <div class="container-fluid">
                    <div class="rowx">
                        <div class="text-center text-danger"><h3>{{getCountdown}}</h3></div>
                        <div class="text-center"><h2>Soal Ke {{no}}</h2></div>
                        <div class="mb-2 noselect content" style="font-size: 20px; user-select: none;" v-html="pertanyaan" draggable="false"></div>
                        <div class="row">
                            <div class="col-12" style="margin-left: 24px; margin-top: 6px;">
                                <div class="form-group">
                                <div class="custom-control custom-radio" style="min-height: 45px;" v-for="(pil, index) in pilihan">
                                  <input class="custom-control-input" type="radio" v-model="jawab" :id="pil.no" :value="pil.no" name="customRadio">
                                  <label :for="pil.no" class="custom-control-label" style="font-size: 20px;user-select: none;" v-html="pil.jawaban"></label>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
                    <br>
                    <div class="row">
                        <!-- <div class="col-6">
                             <router-link :disabled="loading" :to="{path:`/ujian-finish/${kode}`}" v-if="showFinish" class="btn btn-outline btn-lg btn-danger mr-3"><i class="fas fa-arrow-left"></i> Selesai</router-link>
                        </div> -->
                        <div class="col-12 text-center">
                            <button :disabled="loading" class="btn btn-info btn-large mr-2" v-if="no > 1" @click="submit('answer', 'back')">Sebelumnya</button>
                            <button :disabled="loading" class="btn btn-info btn-large mr-2 next" id="next" v-if="no < menuSoal.length" @click="submit('answer', 'next')">Selanjutnya</button>
                            <!-- <button :disabled="loading" class="btn btn-secondary btn-large mr-2" v-if="enableSkip" @click="submit('skip')">Lewati</button> -->
                            <!-- <button :disabled="loading" class="btn btn-success btn-large mr-2" @click="submit('answer')">Jawab</button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
                <div style="padding: 16px; margin-top: 20px;">
                    <div class="row" style="margin-bottom: 12px;">
                        <h5 class="title" style="text-align: center;width: 100%;"><b>Pilih Nomor Soal</b></h5>
                    </div>
                    <div class="row">
                        <button 
                            v-for="item in menuSoal" 
                            :key="item.id" 
                            v-bind:class="{ 'btn btn-small btn-primary': item.selesai, 'btn btn-small btn-outline-primary': item.belum }" 
                            style="margin: 6px;min-height: 36px;"
                            v-on:click="setSoal(item.id)"
                        >{{item.id}}</button>
                    </div>
                    <div class="row" style="margin-top: 16px;">
                        <button :disabled="loading" class="btn btn-block btn-outline-primary" @click="selesai()">Selesai</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- The Right Sidebar -->
        <aside class="control-sidebar control-sidebar-light" style="box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%)">
            <a class="btn btn-small floating-back" data-widget="control-sidebar" href="#"><i class="fas fa-chevron-right"></i></a>
            <div class="row">
                <h5 class="title" style="text-align: center;width: 100%;"><b>Pilih Nomor Soal</b></h5>
            </div>
            <div class="row" style="padding: 16px;">
                <button 
                    v-for="item in menuSoal" 
                    :key="item.id" 
                    v-bind:class="{ 'btn btn-small btn-success': item.selesai, 'btn btn-small btn-primary': item.belum }" 
                    style="margin: 6px;min-height: 36px;"
                    v-on:click="setSoal(item.id)"
                >{{item.id}}</button>
            </div>
        </aside>
        <!-- <a class="floating btn btn-small" data-widget="control-sidebar" href="#"><i class="fas fa-chevron-left"></i></a> -->
    </section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
import { Plugins } from '@capacitor/core';
import { PrivacyScreen } from '@capacitor-community/privacy-screen';
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { Network } from '@capacitor/network';

const enable = async () => {
  await PrivacyScreen.enable();
};
export default {
    name: 'SoalProcess',
    data() {
        return {
            kode: '',
            refid: '',
            loading: false,
            no: '',
            pertanyaan: '',
            countdown: 0,
            pilihan: [],
            showFinish: false,
            enableSkip: true,
            timeout: 0,
            jawab:0,
            menuSoal: [],
            no_soal: '',
            done : '',
            interval: null,
            isLoading: false,
            internet: true,
            sendDataStatus: true,
            sendDataType:'',
            sendDataDirection:'',
            internetSpeed: '',
            type:'',
        };
    },
    created: function () {
        let id = this.$route.params.id;
        let type = this.$route.params.program ? this.$route.params.program : '';
        this.kode = id;
        this.type = type;
        this.isLoading = true;
        this.no_soal = 1;
        this.loadSoal(id);
        enable()
        document.addEventListener('copy', function (event) {
            event.preventDefault();
        });
        document.addEventListener('keydown', function (e) {
            if (e.key === 'F12' || e.keyCode === 123) {
                e.preventDefault();
            }
        });
        document.addEventListener('contextmenu', event => event.preventDefault());
        
    },
    mounted(){
        const e = this.$refs;
        let self = this;

        Network.addListener('networkStatusChange', status => {
          // console.log('Network status changed', status);
          // console.log('submit status', this.sendDataStatus);
          this.internet = status.connected
          if (!status.connected) {
            Swal.fire({
                icon: 'info',
                title: 'Tidak ada internet'
            })
          }
          
          if (!this.sendDataStatus) {
            this.submit(this.sendDataType, this.sendDataDirection)
          }
        });
    },
    components: {
        Loading
    },
    methods: {
        loadSoal(id){
            this.loading = true;
            authFetch("/siswa/test/soal/" + id + '/' + this.no_soal).then((res) => {
                res.json().then((json) => {
                    if(json.success)
                    {
                        this.loading = false;
                        this.isLoading = false;
                        if(json.expired)this.$router.push("/ujian-finish/" + this.kode + '/' + this.type);
                        else{
                            if(!json.soal)
                            {
                                // this.$router.push("/ujian-finish/" + this.kode);
                                if (!this.no_soal) {
                                }
                                this.no_soal = 1;
                                this.loadSoal(id)
                                return;
                            } else {
                                // var interval = '';
                                this.pertanyaan = json.soal.soal;
                                this.pilihan = json.pilihan;
                                this.no = json.soal.nomor_soal;
                                this.timeout = json.timeout;
                                this.refid = json.refid;
                                this.menuSoal = json.menu_soal;
                                this.jawab = json.soal.jawaban;
                                this.no_soal = json.soal.nomor_soal;
                                if (this.interval != null) {
                                    clearInterval(this.interval)
                                }
                                this.countdown = this.timeout - json.now; 
                                this.interval = setInterval(() => {
                                    this.countdown--;
                                }, 1000);
                            }
                        }
                    }
                    else{
                        this.$router.push("/ujian-finish/" + this.kode + '/' + this.type);
                    }
                });
            });
        },
        submit: function(type, direction = 'next', direct = 0)
        {
            this.isLoading = true;
            this.sendDataType = type;
            this.sendDataDirection = direction;
            this.sendDataStatus = false;

            var formData = {
                'kode' : this.kode,
                'no_soal': this.no,
                'jawab' : this.jawab,
                'ref_id' : this.refid,
                'submitType' : type
            };

            this.loading = true;

            if (this.internet) {
                if(type=='answer' && !this.jawab)
                {
                    if (direction == 'next') {
                        if (parseInt(this.no_soal)+1 <= this.menuSoal.length) {
                            this.no_soal = parseInt(this.no_soal)+1;
                        } else {
                            this.no_soal = 1;
                        }
                    } else if (direction == 'back') {
                        if (parseInt(this.no_soal)-1 <= this.menuSoal.length && parseInt(this.no_soal) > 1) {
                            this.no_soal = parseInt(this.no_soal)-1;
                        } else {
                            this.no_soal = 1;
                        }
                    } else {
                        this.no_soal = direct;
                    }
                    this.loadSoal(this.kode);
                    return;
                }
                var data = Object.keys(formData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(formData[key])).join('&')
                authFetch('/siswa/test/submit', {
                    method: 'POST',
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(json => {
                    this.loading = false;
                    if(json.success)
                    {
                        this.sendDataStatus = true;
                        this.jawab = '';
                        if(json.data.tampilSemua)this.showFinish=true;
                        if(json.data.sisa<=1)this.enableSkip = false;
                        if(json.data.expired )this.$router.push("/ujian-finish/" + this.kode + '/' + this.type);// || json.data.endpage
                        else{
                            if(json.data.sisa==0)
                            {
                                // this.no_soal = 1;
                                // this.$router.push("/ujian-finish/" + this.kode)
                                if (direction == 'next') {
                                    if (parseInt(this.no_soal)+1 <= this.menuSoal.length) {
                                        this.no_soal = parseInt(this.no_soal)+1;
                                    } else {
                                        this.no_soal = 1;
                                    }
                                } else if (direction == 'back') {
                                    if (parseInt(this.no_soal)-1 <= this.menuSoal.length && parseInt(this.no_soal) > 1) {
                                        this.no_soal = parseInt(this.no_soal)-1;
                                    } else {
                                        this.no_soal = 1;
                                    }
                                } else {
                                    this.no_soal = direct;
                                }
                                this.loadSoal(this.kode);
                            }
                            else{
                                if (direction == 'next') {
                                    if (parseInt(this.no_soal)+1 <= this.menuSoal.length) {
                                        this.no_soal = parseInt(this.no_soal)+1;
                                    } else {
                                        this.no_soal = 1;
                                    }
                                } else if (direction == 'back') {
                                    if (parseInt(this.no_soal)-1 <= this.menuSoal.length && parseInt(this.no_soal) > 1) {
                                        this.no_soal = parseInt(this.no_soal)-1;
                                    } else {
                                        this.no_soal = 1;
                                    }
                                } else {
                                    this.no_soal = direct;
                                }
                                this.loadSoal(this.kode);
                            }
                        }
                        //this.$router.push("/ujian-process/" + this.kode);
                    }
                });
            } else {
                Swal.fire({
                    icon: 'info',
                    title: 'Tidak ada internet'
                })
                this.isLoading = false;
                this.loading = false;
                return false;
            }
        },
        setSoal: function(val) {
            this.no_soal = val
            this.submit('answer', 'direct', val)
            // this.loadSoal(this.kode)
        },
        selesai: function() {
            this.$router.push("/ujian-finish/" + this.kode + '/' + this.type);
        }
    },
    computed:{
        getCountdown() {
            var mnt = parseInt(this.countdown/60);
            var dtk = this.countdown%60;
            mnt = mnt > 9 ? mnt : ('0'+mnt);
            dtk = dtk > 9 ? dtk : ('0'+dtk);
            var stat = mnt+':'+dtk;
            if (stat == '03:00') {
                Swal.fire({
                    icon: 'info',
                    title: 'Waktu mengerjakan kurang 3 menit lagi!',
                    text: ''
                })
            }
            if (stat == '00:01') {
                Swal.fire({
                    icon: 'info',
                    title: 'Waktu mengerjakan telah habis',
                    text: 'Silahkan pilih selesai untuk mengakhiri ujian!'
                })
                setTimeout(function () {
                    // this.$router.go = "/ujian-finish/"+this.kode
                }, 1500);
                window.location.href = "/ujian-finish/"+this.kode + '/' + this.$route.params.program ?? 0;
            }
            return this.countdown < 0 ? '-': (mnt+':'+dtk);
        }
    },
}
</script>
<style type="text/css" scoped>
    .floating{
        position:fixed;
        width:36px;
        top:70px;
        right:0px;
        background-color:#0C9;
        color:#FFF;
        /*border-radius:50px;*/
        text-align:center;
        /*box-shadow: 2px 2px 3px #999;*/
    }
    .floating-back{
        width:36px;
        top: 28px;
        left: 16px;
        margin-top: 20px;
        margin-left: -34px;
        background-color:#0C9;
        color:#FFF;
        /*border-radius:50px;*/
        text-align:center;
        /*box-shadow: 2px 2px 3px #999;*/
    }
    .custom-control-input {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1rem;
        height: 1.25rem;
        opacity: 0;
    }
    .custom-control {
        position: relative;
        z-index: 1;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }
    .custom-control-label::before {
        width: 1.2rem;
        height: 1.2rem;
        left: -2.5rem;
    }
    .custom-control {
        margin-bottom: 6px !important;
    }

    .custom-control-label::after {
        left: -2.5rem;
    }
</style>