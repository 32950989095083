<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div>
                    <div class="lockscreen-logo">
                        <a href="/ujian"><img src="@/assets/img/logo-tosca.png" alt="logo" width="250"></a>
                    </div>
                    <!-- User name -->
                    <div class="text-center" style="padding: 12px;"><img class="img-bordered-sm" :src="info.img_url" alt="user image" style="width:60%; border-radius:20px;"></div>
                    <br>
                    <br>
                    <br>
                    <div class="text-center"><h5>{{info.display_name}}</h5></div>
                    <br>
                    <br>
                    <div class="row mb-4">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <div class="text-center">
                                <p>Kamu telah sampai di halaman terakhir. Silahkan kembali jika masih perlu melakukan pengecekan ulang selama waktunya masih ada. Jika sudah selesai maka silahkan klik Submit untuk menutup ujian secara manual.</p>
                            </div>
                        </div>
                        <div class="col-2"></div>
                    </div>
                    <div class="text-center"><h5>Daftar Nilai</h5></div>
                    <div class="mb-4" v-if="summary.finished_on && this.summary.tampilkan_nilai == '1'" style="width: 100%;">
                        <div class="box-score">
                            <div class="text-center"><h2>Score Akhir</h2></div>
                            <div class="text-center"><h2>{{ hasil.final_score }}</h2></div>
                            <div class="text-center"><h5>{{ summary.total_benar }} benar dari {{summary.total_soal}} soal</h5></div>
                        </div>
                    </div>
                    <div v-if="summary.finished_on && this.summary.tampilkan_pembahasan == '1'">
                        <div v-for="item in pembahasan" :key="item.id" style="padding-left: 3%;padding-right: 3%;">
                            <div class="col-md-12">
                                <div class="card card-primary collapsed-card">
                                  <div class="card-header">
                                    <h3 class="card-title">Soal Ke {{ item.nomor_soal }}</h3>

                                    <div class="card-tools">
                                      <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus"></i>
                                      </button>
                                    </div>
                                    <!-- /.card-tools -->
                                  </div>
                                  <!-- /.card-header -->
                                  <div class="card-body">
                                    <div class="text-center mb-2" v-html="item.soal"></div>
                                    <div class="text-center"><h2 style="font-size: 1.2rem;">Kunci Jawaban</h2></div>
                                    <h5 class="text-center mb-3 text-bold" v-html="item.kunci_jawaban"></h5>
                                    <div class="text-center"><h2 style="font-size: 1.2rem;">Pembahasan</h2></div>
                                    <div style="overflow: auto;">
                                        <div class="text-center pembahasan" v-html="item.pembahasan"></div>
                                    </div>
                                  </div>
                                  <!-- /.card-body -->
                                </div>
                                <!-- /.card -->
                              </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <!-- ((summary.total_soal!=summary.total_tampil || summary.total_skip>0) && !summary.finished_on) ||  -->
                        <router-link :to="{path:`/ujian-process/${kode}/${type}`}" class="btn btn-outline btn-lg btn-secondary mr-3" v-if="summary.expired === false && summary.finished_on == null" :disabled="loading"><i class="fas fa-arrow-left"></i> Lanjutkan Pengerjaan</router-link>
                        <router-link :to="{path:`/ujian-trial-list/`+this.summary.id_tujuan+`/`+this.summary.ujian_type}" class="btn btn-outline btn-lg btn-secondary mr-3" v-if="summary.finished_on && summary.program_id == 27" :disabled="loading"><i class="fas fa-arrow-left"></i> Kembali ke Daftar Ujian</router-link>
                        <router-link :to="{path:`/ujian-event-list/`+this.summary.id_tujuan+`/`+this.summary.ujian_type}" class="btn btn-outline btn-lg btn-secondary mr-3" v-if="summary.finished_on && summary.program_id == 28" :disabled="loading"><i class="fas fa-arrow-left"></i> Kembali ke Daftar Ujian</router-link>
                        <router-link :to="{path:`/ujian-mapel-list/`+this.summary.id_tujuan+`/`+this.summary.ujian_type+`/`+this.summary.jenis}" class="btn btn-outline btn-lg btn-secondary mr-3" v-if="summary.finished_on && summary.program_id != 27 && summary.program_id != 28 && summary.program_id != null && summary.program_id != ''" :disabled="loading"><i class="fas fa-arrow-left"></i> Kembali ke Daftar Ujian</router-link>
                        <button type="button" class="btn btn-lg btn-primary ml-3" v-if="!summary.finished_on" @click="finish" :disabled="loading"><i class="fas fa-check"></i> Selesai</button>
                    </div>  
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { authFetch, auth } from "@/libs/hxcore";
    export default {
    name: 'SoalFinish',
    data() {
        return {
          kode: '',
          loading:false,
          info: {
              display_name: 'No Name'
          },
          summary: {},
          pembahasan: [],
          hasil: [],
          hasilAll: [],
          type:''
        };
    },
    methods: {
        loadSummary(id, program){
            authFetch("/siswa/test/summary/" + id + '/' + program).then((res) => {
                res.json().then((json) => {
                    this.loading = false;
                    if(json.success)
                    {
                        this.summary = json.data;
                        if (json.data.paket_id) {
                            this.summary.id_tujuan = this.summary.paket_id
                        } else {
                            this.summary.id_tujuan = this.summary.mapel
                        }
                        this.loadPembahasan();
                    }
                });
            });
        },
        finish: function()
        {
            this.loading = true;
            var formData = {
                'kode' : this.kode,
                'hasil_id' : this.summary.id
            };
            var data = Object.keys(formData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(formData[key])).join('&')
            authFetch('/siswa/test/finish', {
                method: 'POST',
                body: data
            })
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(json => {
              this.loading = false;

              if (json.success) {
                this.summary.correct = json.data;
                if (this.summary.tampilkan_pembahasan == '0') {
                    // if (this.summary.program_id == 27) {
                    //     this.$router.push("/ujian-trial-list/"+this.summary.paket_id+`/`+this.summary.ujian_type);
                    // } else if (this.summary.program_id == 28) {
                    //     this.$router.push("/ujian-event-list/"+this.summary.paket_id+`/`+this.summary.ujian_type);
                    // } else {
                    //     this.$router.push("/ujian-mapel-list/"+this.summary.paket_id+`/`+this.summary.ujian_type);
                    // }
                    this.hasil = json.success
                } else {
                    this.loadPembahasan();
                // this.$router.push("/ujian-mapel-list/"+this.summary.mapel);
                }
              }
              // this.loadPembahasan(this.summary.id)
              this.summary.finished_on = true
            });
        },
        loadPembahasan() {
            authFetch("/siswa/test/pembahasan/" + this.summary.ujian_id).then((res) => {
                    res.json().then((json) => {
                        this.loading = false;
                        if(json.success)
                        {
                            this.summary.total_benar = json.total_benar;
                            this.hasil = json.hasil
                            this.pembahasan = json.data
                            this.hasilAll = json.hasilall
                        }
                    });
                });
        },
    },
    created: function () {
        let id = this.$route.params.id;
        let types = this.$route.params.program ? this.$route.params.program : 0;
        this.type = types;
        this.kode = id;
        var info = auth.user();
        this.loading = true;
        this.loadSummary(id, types);
        if(info)this.info = info;
        console.log(this.type);
    },
}
</script>
<style type="text/css" scoped>
    .img-bordered-sm {
        width: 100%;
        padding: 25px;
        border: none;
        box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 6%);
    }

    .box-score {
        width: 50%; 
        background-color: green; 
        border-radius: 10px; 
        margin: auto; 
        color: white; 
        font-weight: 600;
        padding: 8px;
        background: #02AAB0;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #00CDAC, #02AAB0);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #00CDAC, #02AAB0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

    .box-score-full { 
        background-color: green; 
        border-radius: 10px; 
        margin: auto; 
        color: white; 
        font-weight: 600;
        padding: 8px;
        background: #02AAB0;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #00CDAC, #02AAB0);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #00CDAC, #02AAB0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
</style>